import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Collapse from '@mui/material/Collapse';
// import SearchIcon from '@mui/icons-material/Search';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import PinterestIcon from '@mui/icons-material/Pinterest';
import { pageData } from '../../data/pagesData';
import * as Icons from "@mui/icons-material";


const pages = pageData.pages
function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSubMenu, setOpenSubMenu] = React.useState(null);
  const [mobileSubMenu, setMobileSubMenu] = React.useState(null);
  const location = useLocation();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuToggle = (event, pageName) => {
    event.stopPropagation();
    setOpenSubMenu((prev) => (prev === pageName ? null : pageName));
  };

  const handleMobileSubMenuToggle = (event, pageName) => {
    event.stopPropagation();
    setMobileSubMenu((prev) => (prev === pageName ? null : pageName));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenSubMenu(null);
    setMobileSubMenu(null);
  };

  // Attach and clean up event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openSubMenu && !event.target.closest('.submenu')) {
        handleMenuClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openSubMenu]);

  return (
    <>
      {/* Top Border */}

      <Box sx={{ bgcolor: 'grey.400' }} />
      <AppBar position="static" sx={{ bgcolor: '#FFFFFF', color: '#000000', boxShadow: 'none', height: 'auto',borderBottom:'0.5px solid black' }}>
        <Toolbar sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column' }, alignItems: { xs: 'center', md: 'flex-start' } }}>
          {/* Logo Section with Mobile Menu Icon */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', md: 'flex-start' }, width: '100%', marginBottom: { md: '10px' } }}>
            <Typography component={Link} to="/">
              <img
                src={pageData.logoo} // Dynamic logo
                alt="Logo"
                style={{ width: '100%', height: 'auto', maxWidth: '350px' }}
              />
            </Typography>
            {/* Mobile Menu Icon */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Menu Section for Desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex', sm: 'none' }, justifyContent: 'flex-start', alignItems: 'center', position: 'relative', flexGrow: 1 }}>
            {pages.map((page) => (
              <div key={page.name} className="submenu" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    component={Link}
                    to={page.to}
                    sx={{
                      textDecoration: 'none',
                      fontFamily: '"Fira Sans", sans-serif',
                      fontSize: {xs:'14px',md:'13px',lg:'16px'},
                      lineHeight: '24px',
                      color: location.pathname === page.to ? '#00B0FF' : '#000000',
                      marginLeft: '8px',
                      '&:hover': {
                        color: '#00B0FF',
                      },
                    }}
                    onClick={handleMenuClose}
                  >
                    {page.name}
                  </Typography>

                  {/* Arrow icon for dropdown toggle */}
                  {page.subpages && (
                    <IconButton
                      onClick={(e) => handleSubMenuToggle(e, page.name)}
                      sx={{
                        padding: 0,
                        marginLeft: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '24px',
                        width: '24px',
                      }}
                    >
                      <ArrowDropDownIcon sx={{ color: location.pathname === page.to ? '#00B0FF' : '#000000' }} />
                    </IconButton>
                  )}
                </div>

                {/* Dropdown Menu for subpages */}
                {page.subpages && (
                  <Collapse in={openSubMenu === page.name} timeout="auto" unmountOnExit>
                    <Box sx={{
                      position: 'absolute', left: '20px', top: '100%', bgcolor: '#FFFFFF', padding: '10px 40px', boxShadow: 1, borderTop: '4px solid #00B0FF'
                      , maxHeight: '200px', // Set a max height for the dropdown
                      overflow: 'auto',   // Enable scrolling when content overflows
                      zIndex: 9999,         // Ensure it appears above other elements
                    }}>
                      {page.subpages.map((subpage) => (
                        <Typography
                          key={subpage.name}
                          component={Link}
                          to={subpage.to}
                          onClick={handleMenuClose} // Close menu on subpage click
                          sx={{
                            textDecoration: 'none',
                            fontFamily: '"Fira Sans", sans-serif',
                            color: location.pathname === subpage.to ? '#00B0FF' : '#000000',
                            display: 'block',
                            marginBottom: '5px',
                            fontSize: '14px',
                            '&:hover': {
                              color: '#00B0FF',
                            },
                          }}
                        >
                          {subpage.name}
                        </Typography>
                      ))}
                    </Box>
                  </Collapse>
                )}
              </div>
            ))}
          </Box>

           {/* Icons Section */}
           <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', marginLeft: 'auto' }}>
            {pageData?.socialLinks.map((social, index) => {
                 const IconComponent = social?.icon ? Icons[social?.icon] : "";
              return (
              <IconButton key={index} aria-label={social.name} component={Link} to={social.to} target="_blank">
                {<IconComponent/>||''}
              </IconButton>
            )})}
          </Box>

          {/* Mobile Menu for small screens */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, position: 'absolute', top: '60px', left: '0', bgcolor: '#FFFFFF', width: '100%', zIndex: 999 }}>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {pages.map((page) => (
                <div key={page.name}>
                  <MenuItem onClick={() => handleMenuClose()}>
                    <Typography
                      component={Link}
                      to={page.to}
                      onClick={() => {
                        handleMenuClose(); // Ensure menu closes on page click
                      }}
                      sx={{
                        textDecoration: 'none',
                        color: '#000000',
                        '&:hover': { color: '#00B0FF' },
                        display: 'flex',
                        justifyContent: 'space-between', // Space between title and dropdown icon
                        width: '100%',
                        fontFamily: '"Fira Sans", sans-serif',
                      }}
                    >
                      {page.name}
                    </Typography>
                    {page.subpages && (
                      <IconButton onClick={(e) => handleMobileSubMenuToggle(e, page.name)} size="small">
                        <ArrowDropDownIcon />
                      </IconButton>
                    )}
                  </MenuItem>

                  {/* Mobile Submenu */}
                  {page.subpages && (
                    <Collapse in={mobileSubMenu === page.name} timeout="auto" unmountOnExit>
                      <Box sx={{ paddingLeft: 2, display: 'flex', flexDirection: 'column' }}> {/* Set to column direction */}
                        {page.subpages.map((subpage) => (
                          <Typography
                            key={subpage.name}
                            component={Link}
                            to={subpage.to}
                            onClick={handleMenuClose} // Close menu on subpage click
                            sx={{
                              textDecoration: 'none',
                              color: '#000000',
                              '&:hover': {
                                color: '#00B0FF',
                              },
                              padding: '4px 0',
                              fontFamily: '"Fira Sans", sans-serif',
                            }}
                          >
                            {subpage.name}
                          </Typography>
                        ))}
                      </Box>
                    </Collapse>
                  )}
                </div>
              ))}
            </Menu>
          </Box>


        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
