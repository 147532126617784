import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';

const CategoryPage = ({ pageContent }) => {
  const { category } = useParams();
  console.log('Category:', category); // Check the value of category

  if (!category || !pageContent || !pageContent[category]) {
    return (
      <Box sx={{ padding: '20px' }}>
        <Typography variant="h5">Category not found.</Typography>
      </Box>
    );
  }

  const categoryData = pageContent[category];

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        {category} Posts
      </Typography>
      {categoryData.articles.map((post) => (
        <Link
          key={post.id}
          to={`/blog/${category}/${post.id}`}
          style={{
            textDecoration: 'none',
            marginBottom: '10px',
            display: 'block',
          }}
        >
          <Button variant="contained">
            {`Read ${category} Post ${post.id}`}
          </Button>
        </Link>
      ))}
    </Box>
  );
};

export default CategoryPage;
