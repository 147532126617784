import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { pageData } from "../../data/pagesData";
import { Link as RouterLink } from "react-router-dom";
import * as Icons from "@mui/icons-material";

const recentfooter = pageData.recentpost.articles.slice(0, 2);
const category = "recentpost";
// const aboutUs = pageData.aboutUs;
const footerData = pageData?.footerData;
function Footer() {
  return (
    <Box
      sx={{
        bgcolor: "#000",
        color: "#fff",
        padding: "20px 0", // Responsive padding
        mt: "auto", // Push footer to bottom if not enough content
        width: "100%",
      }}
    >
      <Box
        sx={{
          maxWidth: "1400px",
          margin: "0 auto",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        {/* About Us Section */}
        <Box
          sx={{
            flex: 1,
            marginBottom: { xs: "20px", md: "0" },
            paddingRight: { md: "20px" }, // Use padding instead of margin for better spacing on small screens
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}
          >
            {footerData?.title || ""}
          </Typography>
          <Link to="/">
            <img
              src={footerData?.logo}
              alt="Celeb Zero"
              style={{ width: "100%", maxWidth: "300px", marginBottom: "10px" }}
            />
          </Link>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              lineHeight: "1.7",
              fontFamily: '"Fira Sans", sans-serif',
            }}
          >
            {footerData?.description || ""}
          </Typography>
        </Box>

        {/* Recent Posts Section */}
        <Box
          sx={{
            flex: 1,
            marginBottom: { xs: "20px", md: "0" },
            paddingRight: { md: "20px" },
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}
          >
            Recent Posts
          </Typography>
          {recentfooter.map((post, index) => (
            <Box sx={{ display: "flex", marginBottom: "10px" }} key={index}>
              <Link
                component={RouterLink}
                to={`/blog/${category}/${post.id}`} // Changed post to article
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  flex: 1,
                }}
              >
                <img
                  src={post.image}
                  alt={`Post ${index + 1}`}
                  style={{
                    width: "108px",
                    height: "72px", // Maintain aspect ratio
                    maxWidth: "100%", // Make sure it scales down on small screens
                    marginRight: "10px",
                  }}
                />
              </Link>
              <Link
                component={RouterLink}
                to={`/blog/${category}/${post.id}/${post.title}`} // Changed post to article
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  flex: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "1.7",
                    color: "#fff",
                    textDecoration: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "#00B0FF",
                    },
                    fontFamily: '"Fira Sans", sans-serif',
                    marginLeft: "20px",
                  }}
                >
                  {post.title}
                </Typography>
              </Link>
            </Box>
          ))}
        </Box>

        {/* Quick Links Section */}
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}
          >
            Quick Links
          </Typography>
          {footerData.quickLinks.map((link, index) => (
            <Link to={link.link}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  marginTop: "5px",
                  color: "#fff",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#00B0FF",
                  },
                  fontFamily: '"Fira Sans", sans-serif',
                }}
                key={index}
              >
                {link.title}
              </Typography>
            </Link>
          ))}
        </Box>
      </Box>

      {/* Social Media Section */}
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        {pageData?.socialLinks.map((social, index) => {
          const IconComponent = social?.icon ? Icons[social?.icon] : "";
          return (
            <IconButton
              href={social.link}
              target="_blank"
              sx={{ color: "#fff" }}
              key={index}
            >
              {<IconComponent />}
            </IconButton>
          );
        })}
      </Box>

      {/* Footer Bottom */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "20px",
          fontSize: "12px",
          color: "#aaa",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* Left side: Copyright */}
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            marginBottom: { xs: "5px", md: "0" },
            padding: "10px",
          }}
        >
          @2024 - Trending Feed. All Rights Reserved.
        </Typography>

        {/* Right side: Policy Links */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "row" }, // Change to row on larger screens
            textAlign: { xs: "left", md: "right" },
            gap: "5px", // Add some space between the links
          }}
        >
          {footerData.policyLinks.map((policy, index) => (
            <Link to={policy.link}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  padding: "0px 10px",
                  color: "#aaa",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#00B0FF",
                  },
                }}
                key={index}
              >
                {policy.title}
              </Typography>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
